import { ChevronRight, CircleUserRound, XCircle } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { Feature, SubscriptionInfo, User } from "types";

import { AccessCheck } from "@/components/access-check";
import MembersMenu from "@/components/members-menu";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import {
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { aboutLinks, subscriberLinks } from "@/lib/data/navigation";
import { cn } from "@/lib/utils";
import { access as accessTypes, canAccess } from "@/lib/utils/index";

import { ListItem } from "./list-item";

export const AboutMenu = () => (
	<ResponsiveMenu label="About Us" href="/about/">
		<ul className="lg:p-4">
			{aboutLinks.map((link) => (
				<ListItem key={link.href} href={link.href} title={link.name} />
			))}
		</ul>
	</ResponsiveMenu>
);

export const UserMenu = ({
	user,
	subscription,
}: {
	user: User;
	subscription: SubscriptionInfo;
}) => (
	<ResponsiveMenu
		label="Member's Area"
		desktopTrigger={
			<NavigationMenuTrigger>
				<NavigationMenuLink href="/members/profile/">
					<CircleUserRound size={28} strokeWidth="1.2" />
				</NavigationMenuLink>
			</NavigationMenuTrigger>
		}
	>
		<div className="space-y-1.5 px-1.5 py-4 lg:px-6">
			<p className="whitespace-nowrap font-bold text-gray-900">{user?.full_name}</p>
			{subscription ? (
				subscription?.is_active && <p className="text-sm text-gray-600">{subscription?.name}</p>
			) : (
				<p className="text-sm text-gray-600">Free Account</p>
			)}
		</div>
		<hr className="border-gray-300 lg:mx-6" />
		<ul className="lg:p-4">
			<MembersMenu user={user} />
		</ul>
	</ResponsiveMenu>
);

export const ExaminePlusMenu = ({ features }: { features: Feature[] }) => {
	const { showAITooltip, hideAITooltip } = useAITooltip();

	return (
		<ResponsiveMenu
			label="Examine+"
			desktopTrigger={
				<Popover open={showAITooltip}>
					<PopoverTrigger asChild>
						<NavigationMenuTrigger className={navigationMenuTriggerStyles}>
							<NavigationMenuLink href="/plus-info/">Examine+</NavigationMenuLink>
						</NavigationMenuTrigger>
					</PopoverTrigger>
					<PopoverContent className="z-1000 max-w-72">
						<AITooltipContent hideAITooltip={hideAITooltip} />
					</PopoverContent>
				</Popover>
			}
		>
			<ExaminePlusMenuContent features={features} />
		</ResponsiveMenu>
	);
};

interface ResponsiveMenuProps {
	label: string;
	href?: string;
	desktopTrigger?: React.ReactNode;
	children: React.ReactNode;
}

const ResponsiveMenu = ({ label, href, desktopTrigger, children }: ResponsiveMenuProps) => (
	<>
		{/* MENU DESKTOP */}
		<NavigationMenuItem className="hidden lg:flex">
			{desktopTrigger || (
				<NavigationMenuTrigger>
					<NavigationMenuLink href={href}>{label}</NavigationMenuLink>
				</NavigationMenuTrigger>
			)}
			<NavigationMenuContent>{children}</NavigationMenuContent>
		</NavigationMenuItem>

		{/* MENU MOBILE */}
		<Collapsible className="w-full lg:hidden">
			<CollapsibleTrigger className="group flex w-full items-center justify-between data-[state=open]:font-medium">
				<span>{label}</span>
				<ChevronRight className="size-4 transition duration-200 group-data-[state=open]:rotate-90" />
			</CollapsibleTrigger>
			<CollapsibleContent className="px-4 py-1">{children}</CollapsibleContent>
		</Collapsible>
	</>
);

const ExaminePlusMenuContent = ({ features }) => {
	const gptAccess = canAccess(accessTypes.examine_ai, features);

	return (
		<ul className="lg:p-4">
			{subscriberLinks
				.filter((link) => link.href !== "/examine-ai/" || gptAccess)
				.map((link: { href: string; name: string; isIndex: boolean }) => {
					if (link.href === "/examine-ai/") {
						return (
							<AccessCheck
								key={link.href}
								feature={["study_summaries", "supplement_guides", "examine_database"]}
								featureCheck="all"
								cta={
									<ListItem
										key={link.href}
										href={link.href}
										title={link.name}
										isIndex={link.isIndex}
									>
										<div className="flex items-center gap-x-1">
											ExamineAI
											<Badge variant="premium" size="xs" className="inline">
												New
											</Badge>
										</div>
									</ListItem>
								}
							>
								<Tooltip>
									<TooltipTrigger>
										<ListItem
											key={link.href}
											href={link.href}
											title={link.name}
											isIndex={link.isIndex}
										>
											<div className="flex items-center gap-x-1">
												ExamineAI
												<span className="px-0.5 align-super text-xs font-semibold text-red-400">
													BETA
												</span>
												<Badge variant="premium" size="xs" className="inline">
													New
												</Badge>
											</div>
										</ListItem>
									</TooltipTrigger>
									<TooltipContent variant="regular" className="z-1000">
										<div className="space-y-4 text-sm">
											<p>
												Ask questions using ExamineAI, our large language model trained exclusively
												on published Examine content.{" "}
											</p>
											<p>
												You currently have a Legacy membership with limited access.
												<Link href="/plus/" className="link">
													Upgrade to a full Examine+ membership
												</Link>{" "}
												to unlock ExamineAI and everything on the site.
											</p>
										</div>
									</TooltipContent>
								</Tooltip>
							</AccessCheck>
						);
					}
					return (
						<ListItem key={link.href} href={link.href} title={link.name} isIndex={link.isIndex} />
					);
				})}
		</ul>
	);
};

const navigationMenuTriggerStyles = cn(
	"rounded-full bg-premium px-3 py-1.5 font-bold text-primary",
	"decoration-2 transition-colors hover:bg-primary hover:text-white",
	"xl:px-5 xl:py-2"
);

const useAITooltip = () => {
	const [showAITooltip, setShowAITooltip] = useState(true);
	const pathname = usePathname();

	useEffect(() => {
		const showAITooltip = window.localStorage.getItem("showAIPopover");
		const isExamineAIPage = pathname === "/examine-ai/";
		const isMobile = window.innerWidth < 1280;

		if (showAITooltip) {
			setShowAITooltip(showAITooltip === "true");
		}

		if (isExamineAIPage || isMobile) {
			setShowAITooltip(false);
		}
	}, [pathname]);

	const hideAITooltip = () => {
		window.localStorage.setItem("showAIPopover", "false");
		setShowAITooltip(false);
	};

	return { showAITooltip, hideAITooltip };
};

const AITooltipContent = ({ hideAITooltip }) => (
	<div className="relative">
		<Button variant="text" onClick={hideAITooltip} className="absolute -right-1.5 -top-1.5 p-1">
			<XCircle className="size-5 hover:opacity-70" />
		</Button>
		<div className="space-y-2">
			<div className="flex items-center gap-x-2">
				<Badge variant="premium" size="xs" className="inline">
					New
				</Badge>
				<h2 className="text-xl font-semibold">ExamineAI</h2>
			</div>
			<p className="text-start text-base">
				Get the information you need in seconds with{" "}
				<Link href="/examine-ai/" className="link">
					ExamineAI
				</Link>
				, our large language model trained exclusively on tens of millions of words of Examine
				content.
			</p>
			<div className="flex justify-end">
				<Link href="/examine-ai/" className={cn(buttonVariants({}))} onClick={hideAITooltip}>
					Try it now!
				</Link>
			</div>
		</div>
	</div>
);
